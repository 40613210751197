class Person {
    id: string;
    fullName: string;
    age : number;
    constructor(id:string,fullName:string,age:number){
        this.id=id;
        this.fullName=fullName;
        this.age=age;
    }
}
export default Person